/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Image, Text, YouTube, Title, Slideshow, Subtitle, Fullmap, FullmapWrap, FullmapCover, Button } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Studio Petra Nováková"}>
        <SiteHeader set="" currentLanguage={undefined} />

        <Column style={{"marginTop":0,"paddingBottom":0}} name={"9jcd69f9pme"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--top" style={{"marginBottom":0}} columns={"2"} fullscreen={false}>
            
            <ColumnWrapper className="--center">
              
              <Image style={{"maxWidth":195,"marginTop":64,"marginBottom":0}} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/36990/41bff85b032b430fbb7f32f2c6c5c15e_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/36990/41bff85b032b430fbb7f32f2c6c5c15e_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/36990/41bff85b032b430fbb7f32f2c6c5c15e_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/36990/41bff85b032b430fbb7f32f2c6c5c15e_s=1400x_.jpeg 1400w, https://cdn.swbpg.com/t/36990/41bff85b032b430fbb7f32f2c6c5c15e_s=2000x_.jpeg 2000w"} alt={""} src={"https://cdn.swbpg.com/t/36990/41bff85b032b430fbb7f32f2c6c5c15e_s=860x_.jpeg"}>
              </Image>

              <Text className="text-box fs--16" style={{"marginTop":0}} content={"<span style=\"color: var(--white);\">Kadeřnický salon v centru Kutné Hory.</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center">
              
              <YouTube embedId={"EY-STSaD6_Q"} autoplay={true} loop={true} modestbranding={false} controls={false} ivLoadPolicy={false}>
              </YouTube>

            </ColumnWrapper>

            <ColumnWrapper className="--center" style={{"paddingBottom":0}}>
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingTop":0,"paddingBottom":0}} name={"uvod-1"}>
          
          <ColumnWrap className="column__flex --center el--2 flex--center" style={{"maxWidth":"","marginBottom":0,"paddingTop":0}} columns={"2"}>
            
            <ColumnWrapper style={{"maxWidth":500}}>
              
              <Image style={{"maxWidth":363}} alt={""} src={"https://cdn.swbpg.com/t/36990/f36a7032be574cd4813ae0da9d4890ee_s=660x_.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/36990/f36a7032be574cd4813ae0da9d4890ee_s=350x_.jpg 350w, https://cdn.swbpg.com/t/36990/f36a7032be574cd4813ae0da9d4890ee_s=660x_.jpg 660w, https://cdn.swbpg.com/t/36990/f36a7032be574cd4813ae0da9d4890ee_s=860x_.jpg 860w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":500}}>
              
              <Title className="title-box fs--30" content={"Něco málo o mně"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":"","paddingBottom":33}} content={"Jmenuji se Petra Nováková. V oboru kadeřnice působím již mnoho let. Po dobu budování své kariéry jsem působila v několika salonech ve Východních Čechách, kde jsem postupně získávala praxi. Zaměřuji se na sledování aktuálních trendů v oblasti správně zvolené techniky barvení, zesvětlování vlasů a preciznosti střihu. Přijďte si užít příjemnou atmosféru salonu a péči o vaše vlasy. Vaše spokojenost je mým cílem."}>
              </Text>

              <Image style={{"maxWidth":512,"paddingTop":0,"marginTop":15}} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" srcSet={"https://cdn.swbpg.com/t/36990/125290f5ddb74801ae2a00ad37bec54d_s=350x_.jpg 350w, https://cdn.swbpg.com/t/36990/125290f5ddb74801ae2a00ad37bec54d_s=660x_.jpg 660w, https://cdn.swbpg.com/t/36990/125290f5ddb74801ae2a00ad37bec54d_s=860x_.jpg 860w, https://cdn.swbpg.com/t/36990/125290f5ddb74801ae2a00ad37bec54d_s=1400x_.jpg 1400w, https://cdn.swbpg.com/t/36990/125290f5ddb74801ae2a00ad37bec54d_s=2000x_.jpg 2000w"} alt={""} src={"https://cdn.swbpg.com/t/36990/125290f5ddb74801ae2a00ad37bec54d_s=860x_.jpg"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center" style={{"paddingBottom":0,"marginTop":0,"paddingTop":37}} name={"6a8jmvtom1y"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center" content={"Produkty Kerastase"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column style={{"paddingBottom":0,"paddingTop":0}} name={"36ap8on3bz6"}>
          
          <ColumnWrap className="column__flex --center el--3 pt--30" style={{"paddingBottom":0}} columns={"3"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":285}} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/36990/78c4173103a8480588bbb6f02a28ee05_s=350x_.jpg 350w, https://cdn.swbpg.com/t/36990/78c4173103a8480588bbb6f02a28ee05_s=660x_.jpg 660w"} alt={""} src={"https://cdn.swbpg.com/t/36990/78c4173103a8480588bbb6f02a28ee05_s=660x_.jpg"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image style={{"maxWidth":286}} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/36990/a1b2adcf7e3d436bacb99342acd6e0e2_s=350x_.jpg 350w, https://cdn.swbpg.com/t/36990/a1b2adcf7e3d436bacb99342acd6e0e2_s=660x_.jpg 660w, https://cdn.swbpg.com/t/36990/a1b2adcf7e3d436bacb99342acd6e0e2_s=860x_.jpg 860w"} alt={""} src={"https://cdn.swbpg.com/t/36990/a1b2adcf7e3d436bacb99342acd6e0e2_s=660x_.jpg"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image style={{"maxWidth":284}} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/36990/5c82ff513e6e4163b81361c40d654cc9_s=350x_.jpg 350w, https://cdn.swbpg.com/t/36990/5c82ff513e6e4163b81361c40d654cc9_s=660x_.jpg 660w, https://cdn.swbpg.com/t/36990/5c82ff513e6e4163b81361c40d654cc9_s=860x_.jpg 860w"} alt={""} src={"https://cdn.swbpg.com/t/36990/5c82ff513e6e4163b81361c40d654cc9_s=660x_.jpg"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center" style={{"paddingBottom":16,"marginTop":0,"paddingTop":16}} name={"pvkoym2m1wb"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center" content={"<span style=\"color: var(--white);\">Prezentace</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Slideshow style={{"marginTop":0,"marginBottom":0,"paddingTop":0}} name={"2e60cv0392a"} autoplay={true} infinite={true} effect={"slide"} transitionDuration={5}>
          
          <Column style={{"paddingBottom":0,"paddingTop":0}}>
            
            <ColumnWrap className="column__flex --center el--1" columns={"1"}>
              
              <ColumnWrapper >
                
                <Image style={{"maxWidth":360}} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/36990/2ca991fcb44748eeb0bdc599a166c4a9_s=350x_.jpg 350w, https://cdn.swbpg.com/t/36990/2ca991fcb44748eeb0bdc599a166c4a9_s=660x_.jpg 660w, https://cdn.swbpg.com/t/36990/2ca991fcb44748eeb0bdc599a166c4a9_s=860x_.jpg 860w, https://cdn.swbpg.com/t/36990/2ca991fcb44748eeb0bdc599a166c4a9_s=1400x_.jpg 1400w"} alt={""} src={"https://cdn.swbpg.com/t/36990/2ca991fcb44748eeb0bdc599a166c4a9_s=860x_.jpg"}>
                </Image>

              </ColumnWrapper>

            </ColumnWrap>

          </Column>

          <Column >
            
            <ColumnWrap className="column__flex --center el--1" columns={"1"}>
              
              <ColumnWrapper >
                
                <Image style={{"maxWidth":360}} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/36990/d2011ba63d8d4ae2bc9967e606f158a0_s=350x_.jpg 350w, https://cdn.swbpg.com/t/36990/d2011ba63d8d4ae2bc9967e606f158a0_s=660x_.jpg 660w, https://cdn.swbpg.com/t/36990/d2011ba63d8d4ae2bc9967e606f158a0_s=860x_.jpg 860w, https://cdn.swbpg.com/t/36990/d2011ba63d8d4ae2bc9967e606f158a0_s=1400x_.jpg 1400w"} alt={""} src={"https://cdn.swbpg.com/t/36990/d2011ba63d8d4ae2bc9967e606f158a0_s=860x_.jpg"}>
                </Image>

              </ColumnWrapper>

            </ColumnWrap>

          </Column>

          <Column >
            
            <ColumnWrap className="column__flex --center el--1" columns={"1"}>
              
              <ColumnWrapper >
                
                <Image style={{"maxWidth":360}} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/36990/8c64ac47dc284c0b87821107d75e1157_s=350x_.jpg 350w, https://cdn.swbpg.com/t/36990/8c64ac47dc284c0b87821107d75e1157_s=660x_.jpg 660w, https://cdn.swbpg.com/t/36990/8c64ac47dc284c0b87821107d75e1157_s=860x_.jpg 860w, https://cdn.swbpg.com/t/36990/8c64ac47dc284c0b87821107d75e1157_s=1400x_.jpg 1400w"} alt={""} src={"https://cdn.swbpg.com/t/36990/8c64ac47dc284c0b87821107d75e1157_s=860x_.jpg"}>
                </Image>

              </ColumnWrapper>

            </ColumnWrap>

          </Column>

          <Column >
            
            <ColumnWrap className="column__flex --center el--1" columns={"1"}>
              
              <ColumnWrapper style={{"paddingTop":5}}>
                
                <Image style={{"maxWidth":360}} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/36990/c6d1236deca44e8881378d48b79c82c6_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/36990/c6d1236deca44e8881378d48b79c82c6_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/36990/c6d1236deca44e8881378d48b79c82c6_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/36990/c6d1236deca44e8881378d48b79c82c6_s=1400x_.jpeg 1400w"} alt={""} src={"https://cdn.swbpg.com/t/36990/c6d1236deca44e8881378d48b79c82c6_s=860x_.jpeg"}>
                </Image>

              </ColumnWrapper>

            </ColumnWrap>

          </Column>

          <Column style={{"paddingTop":0,"paddingBottom":0}}>
            
            <ColumnWrap className="column__flex --center el--1" columns={"1"}>
              
              <ColumnWrapper style={{"paddingTop":7}}>
                
                <Image style={{"maxWidth":360}} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/36990/79942fd0faea41f3855864835a44306b_s=350x_.jpg 350w, https://cdn.swbpg.com/t/36990/79942fd0faea41f3855864835a44306b_s=660x_.jpg 660w, https://cdn.swbpg.com/t/36990/79942fd0faea41f3855864835a44306b_s=860x_.jpg 860w, https://cdn.swbpg.com/t/36990/79942fd0faea41f3855864835a44306b_s=1400x_.jpg 1400w"} alt={""} src={"https://cdn.swbpg.com/t/36990/79942fd0faea41f3855864835a44306b_s=860x_.jpg"}>
                </Image>

              </ColumnWrapper>

            </ColumnWrap>

          </Column>

          <Column style={{"paddingTop":0,"paddingBottom":20}}>
            
            <ColumnWrap className="column__flex --center el--1" columns={"1"}>
              
              <ColumnWrapper >
                
                <Image style={{"maxWidth":360}} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/36990/775241d18f4d462898e0234a98dcaba9_s=350x_.jpg 350w, https://cdn.swbpg.com/t/36990/775241d18f4d462898e0234a98dcaba9_s=660x_.jpg 660w, https://cdn.swbpg.com/t/36990/775241d18f4d462898e0234a98dcaba9_s=860x_.jpg 860w, https://cdn.swbpg.com/t/36990/775241d18f4d462898e0234a98dcaba9_s=1400x_.jpg 1400w"} alt={""} src={"https://cdn.swbpg.com/t/36990/775241d18f4d462898e0234a98dcaba9_s=860x_.jpg"}>
                </Image>

              </ColumnWrapper>

            </ColumnWrap>

          </Column>

        </Slideshow>


        <Column style={{"paddingTop":6,"paddingBottom":0}} name={"jptun3r9mmk"} layout={"l1"}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"maxWidth":800}} columns={"1"}>
            
            <ColumnWrapper style={{"paddingBottom":29}}>
              
              <Subtitle className="subtitle-box fs--48" content={"<span style=\"color: var(--white);\">Kde nás najdete</span><br>"}>
              </Subtitle>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Fullmap className="--style2" style={{"backgroundColor":"rgba(0,0,0,1)"}} name={"y9o1an5gfge"}>
          
          <FullmapWrap className="--full" style={{"maxWidth":660}}>
            
            <FullmapCover style={{"marginTop":0,"paddingTop":0,"paddingLeft":0,"marginBottom":0,"paddingRight":0}} zoom={"18"} place={"Studio Petra Nováková"}>
            </FullmapCover>

          </FullmapWrap>

        </Fullmap>


        <Fullmap name={"mb6aghp9h3"}>
          
          <FullmapWrap className="--full">
          </FullmapWrap>

        </Fullmap>


        <Column className="--center" style={{"paddingTop":24,"backgroundColor":"rgba(0, 0, 0, 1)","paddingBottom":20}} name={"paticka"}>
          
          <ColumnWrap className="column__flex --center el--1 pb--0 pl--40 pr--40 flex--bottom" columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="--right">
              
              <Button className="btn-box btn-box--pbtn4 btn-box--center" content={"CHCI SE OBJEDNAT"} url={"https://studio-loreal-web.mystoodio.app/cms/reservations/business/1/services"} href={"https://studio-loreal-web.mystoodio.app/cms/reservations/business/1/services"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--4 pb--0 pl--40 pr--40 flex--bottom" style={{"marginTop":0}} columns={"4"} fullscreen={false}>
            
            <ColumnWrapper className="--right">
              
              <Title className="title-box" content={"Adresa<br>"}>
              </Title>

              <Text className="text-box" content={"STUDIO Petra Nováková<br>Vladislavova 332, Kutná Hora\n<br>Česká Republika"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"paddingLeft":0}}>
              
              <Image style={{"maxWidth":136}} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/36990/3d48c0c4abe9456d96bee48b51f9426c_s=350x_.jpg 350w"} alt={""} src={"https://cdn.swbpg.com/t/36990/3d48c0c4abe9456d96bee48b51f9426c_s=350x_.jpg"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--left" style={{"paddingRight":0,"maxWidth":486}}>
              
              <Image style={{"maxWidth":139}} sizes="(max-width: 639px) 100vw, (max-width: 1279px) 50vw, 350px" srcSet={"https://cdn.swbpg.com/t/36990/a940cf41616d4a65980f2cd2fa73b9e1_s=350x_.jpg 350w, https://cdn.swbpg.com/t/36990/a940cf41616d4a65980f2cd2fa73b9e1_s=660x_.jpg 660w, https://cdn.swbpg.com/t/36990/a940cf41616d4a65980f2cd2fa73b9e1_s=860x_.jpg 860w"} alt={""} src={"https://cdn.swbpg.com/t/36990/a940cf41616d4a65980f2cd2fa73b9e1_s=660x_.jpg"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--left">
              
              <Title className="title-box" content={"Kontakt<br>"}>
              </Title>

              <Text className="text-box" content={"+420 605 338 117<br>info@studioloreal.cz<br>www.studioloreal.cz&nbsp;<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":144}} alt={""} src={"https://cdn.swbpg.com/t/36990/fc46577831324ec1a5bd8fab214ef308_s=660x_.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/36990/fc46577831324ec1a5bd8fab214ef308_s=350x_.jpg 350w, https://cdn.swbpg.com/t/36990/fc46577831324ec1a5bd8fab214ef308_s=660x_.jpg 660w"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}